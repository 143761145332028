<template>
  <section class="w-100 products" style="padding: 15px 10px;">
    <div class="row mb-3">
      <div class="col-12 d-flex back-button align-items-center" @click="$emit('backFromVariant','')" style="cursor:pointer">
        <i class="fa-solid mr-2 fa-chevron-left"></i>
        <div>Back to Specific Products</div>
      </div>
    </div>
    <div class=" row questions-not-font flex-column d-flex justify-content-center align-items-center"
      v-if="areNotAvailableProducts">
        <LazyImage :src="'/assets/img/noData.svg'" alt="" style="width: 15rem;" class="mt-5" />
      <div class="row text-center px-2">
        <div class="col-12">
          <h3 class="pt-5" style="   font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 21px;

letter-spacing: 0.04em;

color: #4D4950;">No products found for selected variant!</h3>
        </div>
      
      </div>

    </div>

    <b-skeleton type="input" v-else-if=isLoadingProducts></b-skeleton>
    <div class="" v-else>
      <div class="productSearchInput col-12 row align-items-center mx-1 pr-3 py-1">
        <div style="cursor: pointer">
          <i class="fa fa-search" style="color: #6d6b6d" aria-hidden="true"></i>
        </div>
        <input type="text" class="mx-2 flex-grow-1" v-model="searchProduct" placeholder="Search for Products" />
      </div>
      <!-- <div class="d-flex col-11 p-0 w-100 heading-input-search">
        <label for="search-product" class="font-weight-bold">Search</label>
        <div class="productSearchInput w-100 px-0 ml-2">
          <input id="search-product" class="w-100" type="search" name="search-products" placeholder="Enter Product"
            v-model=searchProduct />
        </div>
      </div> -->

    </div>
    <div class="w-100 p-3 productBox" v-if=isLoadingProducts style="border:none">
      <div class="skel">
        <div class="mb-2 py-1" v-for="index in 5" :Key="index">
          <div class="d-flex  align-items-center">
            <b-skeleton type="avatar" animation="throb"></b-skeleton>
            <b-skeleton type="input" class="ml-2 w-86" animation="throb"></b-skeleton>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="w-100 p-3 productBox" v-if="(!areNotAvailableProducts) && getProducts.length > 0" style="border:none">
      <div :class="['mb-2 py-1,product-row']" v-for="product in getProducts" :key="product.id">
        <div class="d-flex align-items-center product">
          <div class="d-flex col-8 px-0 align-items-center">
            <div class="">

            </div>
            <div class="img ml-2">
              <div v-if="checkFileType(product.image) == 'video'">
                <video ref="videoRef" :src="`${product.image}`" alt="" class="img-thumbnail mr-2" style="height: 50px; width: 50px; border-radius: 50%; object-fit: cover;" controls></video>
              </div>
              <LazyImage :src="productImage(product.image)" :key="1" alt="" class=" product-image" />
            </div>
            <div class="singleProduct d-flex align-items-center pl-2">
              <p class="title text-left">
                {{ product.title }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-100 p-3 productBox" v-if="!this.isLoadingProducts && products.length > 0 && getProducts <= 0" style="border:none">
      <div class="d-flex flex-column h-100 justify-content-center align-items-center text-center mb-2 py-1">
        <div class="btn" v-b-tooltip.hover.top="'Reset Filter'" @click="searchProduct=''"> <i
            class="fa-solid fa-arrow-rotate-right"></i></div>
        <h5>No products were found matching your filter </h5>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  props: {
    products: Array,
    selected: Object,
    isLoadingProducts: Boolean
  },
  computed: {
    areNotAvailableProducts() {
      return this.isLoadingProducts == false && this.products.length <= 0 ? true : false
    },
    ...mapGetters(["getEditorSettings", "GetTokenFromMetaTag", "getQuizID"]),
    checkReset() {

      for (let index in this.productFilters) {
        if (index != 'isFilterVisible') {
           // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          if (this.productFilters[index].selectedOPtion != null)  this.showResetBtn = true
        }
      }
      if (this.productFilters.price.above != '' || this.productFilters.price.start != '' || this.productFilters.price.end != '')
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showResetBtn = true
      return this.showResetBtn
    },

    getProducts() {
      return this.products.filter(item => {
        return (item.title.toLowerCase().includes(this.searchProduct.toLowerCase()))
      })

    }
  },
  watch: {
 
  },
  data() {
    return {
      skeletonCount: 5,
      searchProduct: '',
      isSelectAll: true,
      showResetBtn: false,
      productFilters: {
        price: {
          isActive: false,
          selectedOption: null,
          above: '',
          start: '',
          end: ''
        },
        tags: {
          isActive: false,
          allTags: [],
          selectedOPtion: null,
        },
        vendors: {
          isActive: false,
          selectedOPtion: null,
          allVendors: [],
        },
        collections: {
          isActive: false,
          allCollections: [],
          selectedOPtion: null,
        },
        isFilterVisible: false
      }

    }
  },
  methods: {



    async saveData() {
      const Questions = [this.questions[this.selected.questionIndex]];

      try {
        let data = {
          mapQuestions: JSON.stringify(Questions),
          _token: this.GetTokenFromMetaTag,
          quizId: this.getQuizID,
        };
        const response = await axios.post(`/saveProductMap`, data);
        if (response.status == 200) {
          this.$toasted.show("Product Match Saved", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
            action: {
              class: "customToast",
            },
          });
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured ", {
            theme: "toasted-primary",
            type: "error",
            position: "bottom-center",
            duration: 2000,
          });
        }

        throw error;

      }
      finally {
        // eslint-disable-next-line no-unsafe-finally
        return false
      }

    },
    checkFileType(file) {
      let fileType;
      let allowedImageExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      let allowedVideoExtensions = /(\.mp4)$/i;

      if (!allowedImageExtensions.exec(file)) {
        if (allowedVideoExtensions.exec(file)) {
          fileType = "video";
        } else {
          fileType = "unknown";
        }
      } else {
        fileType = "image";
      }

      return fileType;
    },
    productImage(image) {
      if (image)
        return image.startsWith('upload') ? '/' + image : image
      return 'https://images.quizell.com/default/default_image.png'
    }
  }
}
</script>
<style >
.product-image {
  height: 50px;
  width: 50px;
  /* border: 4px solid rgb(255, 255, 255);  */
  border-radius: 50%;
  object-fit: cover;
  background-size: cover;
  padding: 0.25rem;
  background-color: #fff;
}

.product-icon {
  color: #4d1b7e;

}

.product-icon:hover {
  color: black;
}

.productSearchInput:focus-within {
  outline: 1px solid #4d1b7e;

}

.productSearchInput:focus-within i {
  color: #4d1b7e !important;

}

.productSearchInput {
  border: 0.8px solid #b3afb6;
  box-sizing: border-box;
  border-radius: 6px;
  background: #ffffff;
}

.productSearchInput input {
  background: transparent;
  border: none;
  outline: none;
}

.productSearchInput input:focus {
  border: none;
  outline: none;
}

.filterBtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  letter-spacing: 0.04em;
  color: #4d1b7e;
  cursor: pointer
}

.filterBtn:hover {
  color: black;

}

.w-86 {
  width: 86%;
}

.productBox {
  height: 300px;
  /* border: 0.5px solid #b3afb6; */
  box-sizing: border-box;
  border-radius: 10px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.productBox .product {
  border-radius: 6px;
}

.productBox .product:hover {
  background: #b3afb614;
}

.variant-btn {
  display: none;
  font-size: 14px;
  border: 1px solid #b3afb6;
  border-radius: 12px;
  outline: none
}

.selectedProduct .product:hover .variant-btn {
  border: 1.5px solid #4d1b7e;
  color: #4d1b7e !important;
}

.selectedProduct .product:hover .variant-btn .variant-text {
  color: #4d1b7e !important;
}

.product:hover .variant-btn {
  display: block;
}

.singleProduct .title {
  color: #18191c;
  font-weight: 500;
}

.singleProduct .price {
  color: #4d4950;
  font-weight: 300;
}
</style>